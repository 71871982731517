import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['buttonLeft', 'buttonRight', 'carouselItem']
  static values = {
    itemCount: Number,
  }

  initialize() {
    this.currentItemIndex = 0
  }

  toggleLeftClick() {
    this.setVisibleItem(this.fetchPreviousIndex())
    this.currentItemIndex = this.fetchPreviousIndex()
  }

  toggleRightClick() {
    this.setVisibleItem(this.fetchNextIndex())
    this.currentItemIndex = this.fetchNextIndex()
  }

  setVisibleItem(itemIndex) {
    this.carouselItemTargets.forEach((target, index) => {
      index === itemIndex ? target.classList.add('visible') : target.classList.remove('visible')
    })
  }

  fetchNextIndex() {
    return this.currentItemIndex === this.itemCountValue - 1 ? 0 : this.currentItemIndex + 1
  }

  fetchPreviousIndex() {
    return this.currentItemIndex === 0 ? this.itemCountValue - 1 : this.currentItemIndex - 1
  }
}
